import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from '../../utils/analytics';

import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { SocialIcons } from '../../constants';
import { SpeakerJSON } from '../../types';

type SpeakerInfo = Omit<SpeakerJSON, 'stream' | 'image'> & {
  image: IGatsbyImageData;
};

type DataType = {
  allSpeakersJson: {
    nodes: SpeakerInfo[];
  };
};

export const Speakers = () => {
  const data = useStaticQuery<DataType>(graphql`
    {
      allSpeakersJson {
        nodes {
          name
          social {
            type
            url
          }
          title
          isKeynote
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 80
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `);

  return (
    <div
      id="speakers"
      className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        Speakers
      </h2>

      <div className="mt-6 md:mt-12 w-full lg:w-10/12 grid gap-x-6 gap-y-10 grid-cols-2 justify-center">
        {data.allSpeakersJson.nodes
          .filter((speaker) => speaker.isKeynote)
          .map((speaker, index) => (
            <Speaker key={speaker.speakerId} {...speaker} index={index} />
          ))}
      </div>

      <div className="mt-16 md:mt-16 w-full lg:w-10/12 grid gap-x-6 gap-y-10 grid-cols-2 md:grid-cols-3">
        {data.allSpeakersJson.nodes
          .filter((speaker) => !speaker.isKeynote)
          .map((speaker, index) => (
            <Speaker key={speaker.speakerId} {...speaker} index={index} />
          ))}
      </div>
    </div>
  );
};

const Speaker = ({
  speakerId,
  name,
  image,
  title,
  social,
  isKeynote,
  index,
}: SpeakerInfo & { index: number }) => {
  return (
    <OutboundLink
      key={speakerId}
      href={social.url}
      className="group"
      target="_blank"
    >
      <div className="flex flex-col items-center">
        <div className="relative">
          <div
            className={`${
              isKeynote
                ? 'w-[120px] h-[120px] lg:w-[180px] lg:h-[180px] xl:w-[200px] xl:h-[200px]'
                : 'w-[100px] h-[100px] lg:w-[160px] lg:h-[160px] xl:w-[180px] xl:h-[180px]'
            } rounded-full overflow-hidden will-change-[transform]`}
          >
            <GatsbyImage
              className="md:group-hover:scale-110 transition-transform duration-500 rounded-full overflow-hidden"
              image={getImage(image)}
              alt={name}
              objectFit="cover"
            />
          </div>

          <span
            className={`h-7 w-7 lg:h-9 lg:w-9 xl:h-10 xl:w-10 p-1 lg:p-2 flex items-center justify-center absolute bottom-0 right-0 -translate-y-1/4 -translate-x-1/4 rounded-full border bg-neutral-900 md:group-hover:scale-90 transition-transform duration-500 ${
              index % 2
                ? 'border-primary fill-primary'
                : 'border-secondary fill-secondary'
            }`}
          >
            {SocialIcons[social.type].svg}
          </span>
        </div>
        <div className="mt-5 flex items-center justify-center">
          <h5
            className={`text-center ${
              isKeynote
                ? 'text-xl md:text-2xl xl:text-3xl'
                : 'text-lg md:text-xl xl:text-2xl'
            }`}
          >
            {name}
          </h5>
        </div>
        {isKeynote && (
          <span className="text-center text-sm text-secondary">Keynote</span>
        )}
        <p className="mt-2 text-center opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
          {title}
        </p>
      </div>
    </OutboundLink>
  );
};

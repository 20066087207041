import keyBy from 'lodash/keyBy';
import { graphql, useStaticQuery } from 'gatsby';
import spacetime from 'spacetime';
import { Fragment, useMemo, useState } from 'react';
import { ScheduleDayJSON, Session, SessionJSON, Speaker } from '../../types';
import { ScheduleSession } from './ScheduleSession';
import { BadgeStyle } from '../../styles/constants';
import { TimezoneSelector } from '../TimezoneSelector';
import { useTimezoneContext } from '../../context/TimezoneContext';
import { SessionModal } from './SessionModal';

type DataType = {
  allSpeakersJson: {
    nodes: Speaker[];
  };
  allSessionsJson: {
    nodes: SessionJSON[];
  };
  allScheduleJson: {
    nodes: ScheduleDayJSON[];
  };
};

export const Schedule = () => {
  const { selectedTimezone } = useTimezoneContext();

  const {
    allSpeakersJson: { nodes: speakersRaw },
    allSessionsJson: { nodes: sessionsRaw },
    allScheduleJson: { nodes: scheduleRaw },
  } = useStaticQuery<DataType>(graphql`
    {
      allSpeakersJson {
        nodes {
          speakerId
          name
          social {
            type
            url
          }
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 80
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      allSessionsJson {
        nodes {
          sessionId
          title
          abstract
          speakerIds
        }
      }
      allScheduleJson {
        nodes {
          date
          streams {
            streamId
            name
          }
          timeSlots {
            start
            end
            sessionIds
          }
        }
      }
    }
  `);

  const [selectedSession, setSelectedSession] = useState<Session | null>(null);

  const speakersDictionary = useMemo(
    () => keyBy(speakersRaw, 'speakerId'),
    [speakersRaw]
  );

  const sessionsDictionary = useMemo(() => {
    const processedSessions = sessionsRaw.map((session) => {
      const { speakerIds, ...restSession } = session;
      const sessionSpeakers = speakerIds.map(
        (speakerId) => speakersDictionary[speakerId]
      );
      return {
        ...restSession,
        speakers: sessionSpeakers.filter((s) => s),
      };
    });

    return keyBy(processedSessions, 'sessionId');
  }, [sessionsRaw, speakersDictionary]);

  const schedule = useMemo(() => {
    return scheduleRaw.map((day) => ({
      ...day,
      timeSlots: day.timeSlots.map(({ sessionIds, ...timeSlot }) => ({
        ...timeSlot,
        sessions: sessionIds.map((sessionId) => sessionsDictionary[sessionId]),
      })),
    }));
  }, [scheduleRaw, sessionsDictionary]);

  return (
    <div
      id="schedule"
      className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        <span className="font-display-alt">Sc</span>he
        <span className="font-display-alt">dul</span>e
      </h2>

      <div className="flex items-baseline md:justify-center md:w-6/12 xl:w-4/12 self-stretch md:self-center">
        <p className="mt-3 opacity-70 md:mt-6md:text-center">Showing time in</p>
        <TimezoneSelector className="ml-2 badge-outline" />
      </div>

      <div className="w-full lg:w-10/12">
        {schedule.map(({ date, streams, timeSlots }) => (
          <div key={date} className="relative flex flex-col md:items-center">
            <div className="pt-12 pb-4 flex sticky md:relative top-0 z-10 bg-gradient-to-b from-translucent-background-50 to-translucent-background">
              <h5 className="text-2xl md:text-4xl md:text-center text-primary">
                {spacetime(date).format('{date} {month}')}
              </h5>
            </div>
            <div
              className={`md:mt-4 grid gap-x-3 gap-y-3 md:gap-y-6 md:grid-cols-[max-content_1fr_1fr_1fr]`}
            >
              <div />

              {streams.map(({ streamId, name }) => (
                <div
                  key={streamId}
                  className={`mx-4 hidden md:flex sticky top-[88px] z-10 badge badge-lg badge-outline bg-neutral-900 ${
                    streamId ? BadgeStyle[streamId] : ''
                  }`}
                >
                  {name}
                </div>
              ))}

              {timeSlots.map(({ start, end, sessions }, timeSlotIndex) => (
                <Fragment key={start}>
                  <div
                    className={`${
                      timeSlotIndex > 0 ? 'mt-6' : ''
                    } md:mt-4 text-lg lg:text-xl tabular-nums text-neutral-100`}
                  >
                    <span className="opacity-50">
                      {spacetime(`${date}T${start}`)
                        .goto(selectedTimezone?.value || null)
                        .format('time-24')}
                    </span>

                    <span className="md:block opacity-30">
                      <span className="md:hidden"> - </span>
                      {spacetime(`${date}T${end}`)
                        .goto(selectedTimezone?.value || null)
                        .format('time-24')}
                    </span>
                  </div>
                  {sessions.map((session, index) => {
                    if (!session) {
                      return <div />;
                    }
                    return (
                      <ScheduleSession
                        key={session.sessionId + index}
                        session={session}
                        onClick={() => setSelectedSession(session)}
                        streamName={
                          sessions.length > 1 ? streams[index].name : undefined
                        }
                        streamId={
                          sessions.length > 1
                            ? streams[index].streamId
                            : undefined
                        }
                        className={
                          sessions.length === 1
                            ? 'col-start-2 col-end-[-1]'
                            : ''
                        }
                      />
                    );
                  })}
                </Fragment>
              ))}
            </div>

            <div
              className={`grid grid-cols-[max-content${'_1fr'.repeat(
                streams.length
              )}]`}
            ></div>
          </div>
        ))}
      </div>

      <SessionModal
        session={selectedSession}
        onClose={() => setSelectedSession(null)}
      />
    </div>
  );
};

import HeartsLeft from '../../assets/images/hearts-left.svg';
import HeartsRight from '../../assets/images/hearts-right.svg';
import MinDigTrans from '../../assets/images/logos/ministry-of-digital-transformation.svg';
import EuropeanBank from '../../assets/images/logos/european-bank.svg';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { OutboundLink } from '../../utils/analytics';

export const Organizer = () => {
  return (
    <div
      id="organizer"
      className="container mx-auto py-10 md:py-20 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        Or<span className="font-display-alt">ga</span>niz
        <span className="font-display-alt">er</span>
      </h2>

      <div className="relative mt-6 md:mt-12 w-full lg:w-10/12 h-[240px] md:h-[320px] lg:h-[400px] bg-cover bg-center">
        <StaticImage
          className="absolute inset-0 w-full h-full object-cover rounded-3xl"
          layout="fullWidth"
          alt="Team"
          src="../../assets/images/team.jpeg"
          quality={80}
          formats={['auto', 'webp', 'avif']}
        />
        <img
          src={HeartsLeft}
          className="h-10 md:h-16 absolute top-0 left-10 -translate-y-1/3"
        />

        <img
          src={HeartsRight}
          className="h-10 md:h-16 absolute top-0 right-10 -translate-y-1/3"
        />
      </div>

      <div className="mt-4 md:mt-8 md:w-10/12 lg:w-8/12 xl:w-7/12">
        <h3 className="text-2xl md:text-center">
          GDG Ukraine is a proud organizer of DevFest for Ukraine.
          <br />
          What is GDG?
        </h3>
        <p className="mt-4 opacity-70 md:text-center">
          Google Developers Group (GDG) is where developers of all levels,
          interests, and background meet to learn new skills and share passion
          for technology.
          <br />
          <br />
          GDG Ukraine provides opportunities to learn and grow, meet fellow
          developers and other people in tech, and stay in touch with the local
          tech community.
          <br />
          <br />
          Our mission is to provide an inclusive environment where everyone and
          anyone interested in tech — from beginner developers to experienced
          professionals — will get an opportunity for personal and professional
          growth.
        </p>
      </div>
      <Link
        to="/team"
        className="mt-3 md:mt-6 btn btn-sm btn-outline md:text-center opacity-70"
      >
        Get to know our team
      </Link>

      <div className="mt-8">
        <p className="md:text-center opacity-70">with support of</p>
        <div className="mt-3 flex gap-6 items-center">
          <OutboundLink href="https://thedigital.gov.ua/" target="_blank">
            <img
              src={MinDigTrans}
              alt="Ministry of Digital Transformation"
              className="h-14 opacity-70 hover:opacity-100 transition-opacity duration-500"
            />
          </OutboundLink>
        </div>
      </div>
    </div>
  );
};

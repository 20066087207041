import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { Session } from '../../types';
import {
  AnalyticsEvent,
  OutboundLink,
  trackCustomEvent,
} from '../../utils/analytics';

export const SESSION_MODAL_ID = 'session-modal';

interface SessionModalProps {
  session: Session | null;
  onClose?: () => void;
}

export interface SessionModalRef {
  openModal: () => void;
}

export const SessionModal = forwardRef(
  (
    { session, onClose: onCloseProvided }: SessionModalProps,
    ref?: React.Ref<SessionModalRef>
  ) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    const openModal = useCallback(() => {
      if (checkboxRef.current?.checked !== undefined) {
        checkboxRef.current.checked = true;
        trackCustomEvent({
          category: 'Session modal',
          action: AnalyticsEvent.OpenSession,
          label: session?.title || '',
        });
      }
    }, [session]);

    useEffect(() => {
      if (session) {
        openModal();
      }
    }, [openModal, session]);

    const onClose = useCallback(() => {
      if (checkboxRef.current?.checked !== undefined) {
        checkboxRef.current.checked = false;
        onCloseProvided?.();
      }
    }, [onCloseProvided]);

    useImperativeHandle(
      ref,
      () => ({
        openModal,
      }),
      [openModal]
    );

    const { title, abstract, speakers } = session || {};

    return (
      <>
        <input
          ref={checkboxRef}
          type="checkbox"
          id={SESSION_MODAL_ID}
          className="modal-toggle"
        />
        <div className="modal cursor-pointer" onClick={onClose}>
          <div className="modal-box relative">
            <button
              className="btn btn-sm btn-circle absolute right-2 top-2"
              onClick={onClose}
            >
              ✕
            </button>

            <h5 className="text-2xl">{title}</h5>

            <p className="mt-4 opacity-70 whitespace-pre-wrap">{abstract}</p>

            <div className="mt-8 grid gap-2">
              {speakers?.map(({ speakerId, name, image, title, social }) => (
                <OutboundLink
                  key={speakerId}
                  href={social.url}
                  className="flex items-center group"
                  target="_blank"
                >
                  <GatsbyImage
                    className="mt-1.5 w-[48px] h-[48px] rounded-full overflow-hidden will-change-[transform]"
                    image={getImage(image)}
                    alt={name}
                    objectFit="cover"
                  />
                  <div className="ml-4 flex-1 flex flex-col">
                    <p className="text-md opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
                      {name}
                    </p>
                    <p className="text-sm opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
                      {title}
                    </p>
                  </div>
                </OutboundLink>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
);

SessionModal.displayName = 'SessionModal';

import { OutboundLink } from '../../utils/analytics';
import GoogleCloud from '../../assets/images/logos/google_cloud.svg';
import GoogleDevs from '../../assets/images/logos/google_devs.svg';
import Outreach from '../../assets/images/logos/outreach.svg';
import Wix from '../../assets/images/logos/wix.svg';
import Lemon from '../../assets/images/logos/lemon.svg';
import Avenga from '../../assets/images/logos/avenga.svg';
import JetSoftPro from '../../assets/images/logos/jetsoft_pro.svg';
import Flutter from '../../assets/images/logos/flutter.svg';
import Monday from '../../assets/images/logos/monday.svg';
import Kiwi from '../../assets/images/logos/kiwi.svg';
import AppsFlyer from '../../assets/images/logos/apps-flyer.svg';
import AppsFlame from '../../assets/images/logos/appsflame.svg';
import Hopin from '../../assets/images/logos/hopin.svg';

const LEVELS = {
  general: {
    label: 'General',
    classNames: 'border-1 border-secondary text-secondary',
  },
  graphene: {
    label: 'Graphene',
    classNames: 'border-1 border-primary text-primary',
  },
  diamond: {
    label: 'Diamond',
    classNames: 'border-1 border-primary text-primary',
  },
  platinum: {
    label: 'Platinum',
    classNames: 'border-1 border-secondary text-secondary',
  },
  gold: {
    label: 'Gold',
    classNames: 'bg-primary text-neutral-900',
  },
  hosting: {
    label: 'Hosting partner',
    classNames: 'bg-secondary text-neutral-900',
  },
  silver: {
    label: 'Silver',
    classNames: 'bg-secondary text-neutral-900',
  },
} as const;

const SPONSORS: {
  logo: any;
  name: string;
  link: string;
  cta?: {
    label: string;
    link: string;
  };
  level: keyof typeof LEVELS;
}[] = [
  {
    logo: GoogleDevs,
    name: 'Google Developers',
    link: 'https://developers.google.com/community/gdg',
    cta: {
      label: 'Find a GDG close to you',
      link: 'https://gdg.community.dev/',
    },
    level: 'general',
  },
  {
    logo: GoogleCloud,
    name: 'Google Cloud',
    link: 'https://cloud.google.com/',
    cta: {
      label: 'Careers',
      link: 'https://careers.google.com/cloud/',
    },
    level: 'graphene',
  },
  {
    logo: Outreach,
    name: 'Outreach',
    link: 'https://www.outreach.io/',
    cta: {
      label: 'We are hiring in Prague!',
      link: 'http://bit.ly/join-outreach-prague',
    },
    level: 'graphene',
  },
  {
    logo: Wix,
    name: 'WIX',
    link: 'https://www.wix.engineering/',
    cta: {
      label: 'Careers',
      link: 'https://www.wix.com/jobs/home',
    },
    level: 'graphene',
  },
  {
    logo: Avenga,
    name: 'Avenga',
    link: 'https://www.avenga.com/',
    cta: {
      label: 'Careers',
      link: 'https://www.avenga.com/career/overview/',
    },
    level: 'graphene',
  },
  {
    logo: Lemon,
    name: 'lemon.io',
    link: 'https://lemon.io/for-developers/?utm_source=conference&utm_medium=website&utm_campaign=devfest_supply_en_may_2022&utm_id=im',
    cta: {
      label: 'Work with top startups remotely',
      link: 'https://lemon.io/for-developers/?utm_source=promo&utm_medium=conferene&utm_campaign=devfest_supply_en_may_2022&utm_id=im',
    },
    level: 'platinum',
  },
  {
    logo: Flutter,
    name: 'Flutter',
    link: 'https://flutter.dev/',
    cta: {
      label: 'Flutter Codelabs',
      link: 'https://docs.flutter.dev/codelabs',
    },
    level: 'platinum',
  },
  {
    logo: Monday,
    name: 'Monday',
    link: 'https://monday.com/',
    cta: {
      label: 'Careers',
      link: 'https://monday.com/careers/',
    },
    level: 'gold',
  },
  {
    logo: AppsFlyer,
    name: 'AppsFlyer',
    link: 'https://www.appsflyer.com/',
    cta: {
      label: 'Careers',
      link: 'https://careers.appsflyer.com/',
    },
    level: 'gold',
  },
  {
    logo: Hopin,
    name: 'Hopin',
    link: 'https://hopin.com/contact-sales?utm_source=gdg&utm_medium=devfest&utm_campaign=gdg_dev_fest_ukraine',
    cta: {
      label: 'Contact Sales',
      link: 'https://hopin.com/contact-sales?utm_source=gdg&utm_medium=devfest&utm_campaign=gdg_dev_fest_ukraine',
    },
    level: 'hosting',
  },
  {
    logo: JetSoftPro,
    name: 'JetSoft Pro',
    link: 'https://jetsoftpro.com',
    level: 'silver',
  },
  {
    logo: Kiwi,
    name: 'Kiwi',
    link: 'https://www.kiwi.com',
    level: 'silver',
  },
  {
    logo: AppsFlame,
    name: 'AppsFlame',
    link: 'https://jobs.dou.ua/companies/appflame/',
    level: 'silver',
  },
];

export const Sponsors = () => {
  return (
    <div
      id="sponsors"
      className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        <span className="font-display-alt">Sp</span>onso
        <span className="font-display-alt">r</span>s
      </h2>

      {SPONSORS.length > 0 && (
        <>
          <div className="flex md:justify-center md:w-6/12 xl:w-4/12 self-stretch md:self-center">
            <p className="mt-3 md:mt-6 opacity-70 md:text-center">
              These wonderful organizations donated above and beyond to help us
              reach our donation goals
            </p>
          </div>
          <div className="mt-6 md:mt-12 w-full lg:w-10/12 grid gap-x-6 gap-y-10 grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {SPONSORS.map(({ logo, name, level, link, cta }, index) => (
              <div
                key={index}
                className="flex flex-col justify-center items-center"
              >
                <OutboundLink
                  href={link}
                  className="relative w-full h-full flex flex-col justify-center items-center bg-neutral-800 rounded-xl py-7 px-2 md:py-14 md:px-4 group"
                  target="_blank"
                >
                  <img
                    src={logo}
                    alt={name}
                    className="w-3/4 max-h-3/4 opacity-70 group-hover:opacity-100 transition-opacity duration-500 max-h-16"
                  />

                  {cta && (
                    <OutboundLink
                      href={cta.link}
                      className="mt-2 text-sm underline underline-offset-4 opacity-70 hover:opacity-100 transition-opacity duration-500 text-center"
                      target="_blank"
                    >
                      {cta.label}
                    </OutboundLink>
                  )}
                  <span
                    className={`py-3 px-3 md:py-4 md:px-6 absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 badge bg-neutral-900 ${LEVELS[level].classNames}`}
                  >
                    {LEVELS[level].label}
                  </span>
                </OutboundLink>
              </div>
            ))}
          </div>
        </>
      )}

      <div
        className={`${
          SPONSORS.length ? 'mt-8 md:mt-16' : 'mt-4 md:mt-8'
        } flex flex-col md:items-center lg:w-10/12 xl:w-8/12 self-stretch lg:self-center`}
      >
        <p className="text-xl md:text-2xl md:text-center">
          Want to join us in our mission?
        </p>
        <p className="mt-2">
          <OutboundLink
            href="mailto:devfest@gdg.org.ua"
            target="_blank"
            className="underline underline-offset-4"
          >
            Drop us a message
          </OutboundLink>{' '}
          and we will provide more information about sponsorship.
        </p>
      </div>
    </div>
  );
};

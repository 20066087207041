import { EmojiImages } from '../../constants';
import { OutboundLink } from '../../utils/analytics';

const LINKS = [
  {
    label: "Ukrainian's life at a war",
    link: 'http://war.ukraine.ua/',
  },
  {
    label: 'Hire Ukrainians',
    link: 'https://www.uatalents.com/for-employer',
  },
  {
    label: 'Buy Ukrainian products',
    link: 'https://spendwithukraine.com/',
  },
  {
    label: 'Donate crypto',
    link: 'https://donate.thedigital.gov.ua/',
  },
];

const LEARN_MORE_LINK = 'https://www.standwithukraine.how/';

export const UkrainianLinks = () => {
  return (
    <div
      id="sponsors"
      className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        Ukr<span className="font-display-alt">a</span>ine no
        <span className="font-display-alt">w</span>
      </h2>

      <div className="mt-6 md:mt-12 w-full lg:w-8/12 xl:w-6/12 grid gap-x-10 gap-y-8 grid-cols-2">
        {LINKS.map(({ label, link }, index) => (
          <div key={link} className="flex items-center">
            <div className="flex justify-center w-10 h-10 lg:w-20 lg:h-20 items-center bg-neutral-800 rounded-full">
              <img
                src={
                  index % 3 ? EmojiImages.HeartBlue : EmojiImages.HeartYellow
                }
                className="h-4 md:h-5 lg:h-8"
              />
            </div>
            <OutboundLink
              className="ml-4 lg:text-xl underline underline-offset-4 flex-1"
              href={link}
              target="_blank"
            >
              {label}
            </OutboundLink>
          </div>
        ))}
      </div>
    </div>
  );
};

import './styles.css';

import { Link } from 'gatsby';
import { AnalyticsEvent, trackCustomEvent } from '../../utils/analytics';
import { StaticImage } from 'gatsby-plugin-image';
import { useYoutubeContext } from '../../context/YoutubeContext';
import { useCallback } from 'react';
import { useTimezoneContext } from '../../context/TimezoneContext';
import { TimezoneSelector } from '../TimezoneSelector';
import spacetime from 'spacetime';

const PROMO_VIDEO_ID = '0Zwtv64KJvk';
const TIME = '2022-06-14T16:00:00.000Z';

export const Hero = () => {
  const { selectedTimezone } = useTimezoneContext();
  const { openVideo } = useYoutubeContext();

  const openPromoVideo = useCallback(() => {
    openVideo(PROMO_VIDEO_ID);
  }, [openVideo]);

  return (
    <div className="relative bg-cover bg-center pt-[80px] md:pt-[160px] pb-8 md:pb-24">
      <StaticImage
        className="md:hidden absolute inset-0 w-full h-full object-cover"
        style={{ position: 'absolute' }}
        layout="fullWidth"
        alt=""
        src="../../assets/images/hero-mobile.png"
        quality={80}
        breakpoints={[768]}
        formats={['auto', 'webp', 'avif']}
      />
      <StaticImage
        className="hidden md:block absolute inset-0 w-full h-full object-cover"
        style={{ position: 'absolute' }}
        layout="fullWidth"
        alt=""
        src="../../assets/images/hero.png"
        quality={80}
        formats={['auto', 'webp', 'avif']}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-neutral-900 to-translucent-background" />

      <div className="relative container mx-auto px-4 active fade-bottom">
        <div className="relative my-10">
          <h1 className="font-display text-7xl md:text-9xl lg:text-xxl group">
            <span className="relative flex items-end">
              De<span className="font-display-alt">v</span>Fe
              <span className="font-display-alt">st</span>
              <span className="absolute badge badge-dfua badge-primary top-4 left-16 md:top-8 md:left-32 lg:left-36 md:group-hover:-translate-x-4 md:group-hover:-translate-y-2 transition-transform duration-500">
                online
              </span>
              <button
                className="hidden md:inline-flex ml-4 gap-2 btn btn-lg h-[4rem] px-[2rem] rounded-full btn-outline relative"
                onClick={openPromoVideo}
              >
                <span className="custom-ping absolute inline-flex -top-1 -bottom-1 w-full rounded-full border border-white" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                </svg>
                See how it was
              </button>
            </span>
            <span className="flex flex-col md:flex-row">
              <span>
                fo<span className="font-display-alt">r</span>
              </span>
              <span className="md:ml-8 relative flex self-start">
                U<span className="font-display-alt">kra</span>ine
                <span className="absolute badge badge-dfua badge-secondary right-0 top-4 md:top-8 translate-x-1/4 md:group-hover:translate-x-1/3 md:group-hover:translate-y-2 transition-transform duration-500">
                  Charitable Tech Conference
                </span>
              </span>
            </span>
          </h1>

          <button
            className="md:hidden mt-4 gap-2 btn rounded-full btn-outline relative"
            onClick={openPromoVideo}
          >
            <span className="custom-ping absolute inline-flex -top-1 -bottom-1 w-full rounded-full border border-white" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
            </svg>
            See how it was
          </button>

          <div className="mt-8 md:mt-12 md:w-11/12 lg:w-10/12 xl:w-full grid gap-4 md:gap-12 lg:gap-16 xl:gap-24 md:grid-cols-[1fr_max-content] lg:grid-cols-[max-content_1fr]">
            <p className="text-lg">
              Learn from speakers that shape the future of Android, Web and AI.
              <br />
              DevFest is over. Thank you for joining the cause!
            </p>

            {/* <div className="relative flex flex-col">
              <p className="text-2xl text-primary text-left">
                June 14-15 <br />
                {spacetime(TIME)
                  .goto(selectedTimezone?.value || null)
                  .format('time-24')}
              </p>
              <TimezoneSelector className="mt-1 select-secondary text-secondary bg-transparent" />
            </div> */}

            <Link
              to="/#tickets"
              className="mt-4 btn btn-primary md:hidden"
              onClick={() => {
                trackCustomEvent({
                  category: 'Hero',
                  action: AnalyticsEvent.CTA,
                  label: 'Tickets',
                });
              }}
            >
              Session recordings
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import { ChangeEvent, HTMLAttributes, useCallback } from 'react';
import { useTimezoneContext } from '../../context/TimezoneContext';
import { AnalyticsEvent, trackCustomEvent } from '../../utils/analytics';

interface TimezoneSelectorProps {
  className?: HTMLAttributes<HTMLDivElement>['className'];
}

export const TimezoneSelector = ({ className }: TimezoneSelectorProps) => {
  const { timezones, selectedTimezone, setTimezone } = useTimezoneContext();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      if (timezones && e.target.value !== undefined) {
        const timezone = timezones.find((tz) => tz.value === e.target.value);
        if (timezone) {
          setTimezone(timezone);

          trackCustomEvent({
            category: 'TimezoneSelector',
            action: AnalyticsEvent.ChangeTimezone,
            label: timezone.value,
          });
        }
      }
    },
    [timezones, setTimezone]
  );

  return (
    <select
      className={`w-[7.4rem] outline-none select select-xs ${className}`}
      value={selectedTimezone?.value}
      onChange={handleChange}
    >
      {timezones?.map((tz, index) => (
        <option key={index} value={tz.value}>
          {tz.label}
        </option>
      ))}
    </select>
  );
};

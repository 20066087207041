import { Cause } from '../components/Cause';
import { CookieConsent } from '../components/CookieConsent';
import { Footer } from '../components/Footer';
// import { GeneralInfo } from '../components/GeneralInfo';
// import { GeneralNumbers } from '../components/GeneralNumbers';
import { Goal } from '../components/Goal';
import { Hero } from '../components/Hero';
import { Countdown } from '../components/Hero/Countdown';
import { Navigation } from '../components/Navigation';
import { Organizer } from '../components/Organizer';
import { PreviousHighlights } from '../components/PreviousHighlights';
import { Schedule } from '../components/Schedule';
import { SEO } from '../components/SEO';
import { Speakers } from '../components/Speakers';
import { Sponsors } from '../components/Sponsors';
import { Subscribe } from '../components/Subscribe';
import { SubscribeModal } from '../components/Subscribe/SubscribeModal';
import { Tickets } from '../components/Tickets';
import { UkrainianLinks } from '../components/UkrainianLinks';
import { EmojiImages } from '../constants';
import { useReveal } from '../hooks/useReveal';

const IndexPage = () => {
  useReveal();

  return (
    <main className="bg-primary-content">
      <SEO />
      <Navigation />
      <Hero />
      {/* <Countdown /> */}
      <Cause />
      <Goal />
      {/* <GeneralInfo /> */}
      <Speakers />
      <Schedule />
      {/* <GeneralNumbers /> */}
      <Tickets />
      <Sponsors />
      <Organizer />
      <PreviousHighlights />
      <UkrainianLinks />
      <Subscribe />

      <div className="container mx-auto py-10 md:py-20 px-4 flex md:justify-center">
        <h5 className="font-display text-5xl md:text-9xl lg:text-xxl reveal fade-bottom">
          <span className="font-display-alt">S</span>ee{' '}
          <span className="font-display-alt">you</span>!
          <img
            src={EmojiImages.Flag}
            className="ml-2 md:ml-4 inline h-12 md:h-[110px] lg:h-[150px]"
          />
        </h5>
      </div>

      <Footer />
      <CookieConsent />
      <SubscribeModal />
    </main>
  );
};

export default IndexPage;

import { useCallback } from 'react';

import DocumentaryWebm from '../../assets/videos/documentary.webm';
import Documentary from '../../assets/videos/documentary.mp4';
import DocumentaryFallback from '../../assets/videos/documentary_fallback.webp';
import { useYoutubeContext } from '../../context/YoutubeContext';

const VIDEO_ID = 'tG93qoXnogA';

export const PreviousHighlights = () => {
  const { openVideo } = useYoutubeContext();

  const onVideoReady = useCallback((node) => {
    node?.play();
  }, []);

  const openHighlightVideo = useCallback(() => {
    openVideo(VIDEO_ID);
  }, [openVideo]);

  return (
    <div className="container mx-auto py-10 md:py-20 px-4 flex flex-col md:items-center reveal fade-bottom">
      <h2 className="font-display text-4xl md:text-5xl lg:text-6.5xl md:text-center">
        <span className="font-display-alt">S</span>ee how i
        <span className="font-display-alt">t</span> w
        <span className="font-display-alt">as</span> bef
        <span className="font-display-alt">or</span>e the{' '}
        <span className="font-display-alt">w</span>ar
      </h2>

      <div
        className="relative mt-6 md:mt-12 w-full lg:w-10/12 rounded-3xl flex justify-center items-center group cursor-pointer"
        onClick={openHighlightVideo}
      >
        <video
          ref={onVideoReady}
          className="object-cover w-full h-[240px] md:h-[320px] lg:h-[400px] rounded-3xl"
          poster={DocumentaryFallback}
          muted
          loop
        >
          <source src={DocumentaryWebm} type="video/webm" />
          <source src={Documentary} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos.
        </video>

        <div className="absolute inset-0 bg-neutral-900 opacity-20" />
        <svg
          className="absolute h-12 w-12 opacity-40 md:group-hover:scale-125 md:group-hover:opacity-50 transition-all duration-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="currentColor"
        >
          <path d="M256 0C114.618 0 0 114.618 0 256s114.618 256 256 256 256-114.618 256-256S397.382 0 256 0zm0 469.333c-117.818 0-213.333-95.515-213.333-213.333S138.182 42.667 256 42.667 469.333 138.182 469.333 256 373.818 469.333 256 469.333z" />
          <path d="m375.467 238.933-170.667-128c-14.064-10.548-34.133-.513-34.133 17.067v256c0 17.58 20.07 27.615 34.133 17.067l170.667-128c11.377-8.534 11.377-25.6 0-34.134zm-162.134 102.4V170.667L327.111 256l-113.778 85.333z" />
        </svg>
      </div>
    </div>
  );
};

import { OutboundLink } from '../../utils/analytics';
import { formatPrice } from '../../utils/priceFormatter';
import { Link } from 'gatsby';
import { GoalProgressBar } from './GoalProgressBar';
import { useMoneyStatistics } from '../../hooks/useMoneyStatistics';
import { ORGANIZATIONS } from '../../data/organizations';

export const Goal = () => {
  const { target } = useMoneyStatistics();

  return (
    <div
      id="goal"
      className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
    >
      <h2 className="font-display text-4xl md:text-7xl lg:text-8xl md:text-center">
        Our <span className="font-display-alt">g</span>oal wa
        <span className="font-display-alt">s</span> to rais
        <span className="font-display-alt">e</span>{' '}
        {formatPrice(target).split(',')[0]}K
      </h2>

      <div className="mt-4 md:mt-8 md:w-10/12 lg:w-8/12 xl:w-6/12 flex flex-col md:items-center">
        <p className="md:text-center opacity-70">
          This roughly equals to 1000 medical kits, 400 body armours, or 74 tons
          of humanitarian aid, that could save thousands of Ukrainians' lives.
          <br />
          <br />
          All funds raised during the event will be split between 3
          Non-Governmental Organizations & Humanitarian funds that we are
          collaborating with:
        </p>

        <div className="mt-6 md:mt-8 grid grid-cols-2 gap-6 md:gap-12 lg:gap-24 md:grid-cols-3 justify-items-center">
          {ORGANIZATIONS.map(({ name, image, link, className }) => (
            <OutboundLink href={link} key={name} target="_blank">
              <img
                src={image}
                alt={name}
                className={`h-14 md:h-20 opacity-70 hover:opacity-100 transition-opacity duration-500 ${className}`}
              />
            </OutboundLink>
          ))}
        </div>

        <Link
          to="/why-these-organizations"
          className="mt-6 opacity-70 underline underline-offset-4 md:text-center modal-button cursor-pointer"
        >
          Learn why we chose them
        </Link>
      </div>

      <GoalProgressBar />
    </div>
  );
};

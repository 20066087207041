import CauseImg from '../../assets/images/cause.svg';

const DATA = [
  {
    header:
      'Every day millions of Ukrainians fight for their lives because of Russian invasion',
    body: 'Volunteers, individuals and organizations combine their efforts to provide support, give shelter to refugees and raise funds for those in need.',
  },
  {
    header: 'Uniting the global tech community to support Ukraine',
    body: 'Google Developer Groups in Ukraine came together to organize DevFest for Ukraine — a global online tech conference to share a passion for technology with international community and raise funds for Ukraine.',
  },
];

export const Cause = () => {
  return (
    <div
      id="cause"
      className="container mx-auto py-12 md:py-24 px-4 flex justify-center reveal fade-bottom"
    >
      <div className="lg:w-10/12 xl:w-8/12 grid gap-8 md:gap-16 md:grid-cols-[max-content_1fr]">
        <img src={CauseImg} className="w-2/5 md:w-[250px]" alt="Ukraine" />

        <div className="flex flex-col md:justify-center">
          {DATA.map(({ header, body }, index) => (
            <div key={index} className={index ? 'mt-8' : ''}>
              <h3 className="text-2xl">{header}</h3>
              <p className="mt-4 opacity-70">{body}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { HTMLAttributes } from 'react';
import { BadgeStyle } from '../../styles/constants';
import { Session, Stream } from '../../types';

interface SessionProps {
  session: Session;
  streamName?: string;
  streamId?: Stream;
  className: HTMLAttributes<HTMLDivElement>['className'];
  onClick: () => void;
}
export const ScheduleSession = ({
  session,
  streamId,
  streamName,
  className,
  onClick,
}: SessionProps) => {
  const { title, speakers } = session;
  return (
    <button
      className={`p-4 relative h-full flex flex-col justify-between bg-neutral-800 rounded-xl text-left group ${className}`}
      onClick={onClick}
    >
      {streamName && (
        <span
          className={`absolute top-0 -translate-y-1/3 right-2 md:hidden badge badge-sm badge-outline bg-neutral-900 ${
            streamId ? BadgeStyle[streamId] : ''
          } `}
        >
          {streamName}
        </span>
      )}

      <h5 className="mb-4 md:mb-8 text-lg">{title}</h5>
      <div className="grid gap-2">
        {speakers.map(({ speakerId, name, image, title }) => (
          <div key={speakerId} className="flex">
            <GatsbyImage
              className="mt-1.5 w-[32px] h-[32px] rounded-full overflow-hidden will-change-[transform]"
              image={getImage(image)}
              alt={name}
              objectFit="cover"
            />
            <div className="ml-2 flex-1 flex flex-col">
              <p className="text-md opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
                {name}
              </p>
              <p className="text-sm opacity-70 md:group-hover:opacity-100 transition-opacity duration-500">
                {title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </button>
  );
};

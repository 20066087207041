import { AnalyticsEvent, trackCustomEvent } from '../../utils/analytics';
import { useCallback, useEffect, useRef, useState } from 'react';
import MailchimpSubscribe, {
  FormHooks,
  NameFormFields,
} from 'react-mailchimp-subscribe';

const MAILCHIMP_URL =
  'https://gdg.us11.list-manage.com/subscribe/post?u=b7e853a79164ddfdbda3ed77b&id=7993e39fbe';

export const SubscribeModal = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onCloseModal = useCallback(() => {
    if (inputRef.current?.checked) {
      inputRef.current.checked = false;
    }
  }, []);

  return (
    <>
      <input
        ref={inputRef}
        type="checkbox"
        id="subscribe-modal"
        className="modal-toggle"
      />
      <label htmlFor="subscribe-modal" className="modal cursor-pointer">
        <label className="modal-box relative" htmlFor="">
          <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                subscribe={subscribe}
                closeModal={onCloseModal}
              />
            )}
          />
        </label>
      </label>
    </>
  );
};

type CustomFormProps = FormHooks<NameFormFields> & {
  closeModal: () => void;
};

const CustomForm = ({
  status,
  message,
  subscribe,
  closeModal,
}: CustomFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const clearFields = useCallback(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      subscribe({
        EMAIL: email,
        FNAME: firstName,
        LNAME: lastName,
      });
      trackCustomEvent({
        category: 'Subscribe modal',
        action: AnalyticsEvent.SubscribeToEmails,
        label: 'Subscribe',
      });
    },
    [email, firstName, lastName, subscribe]
  );

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (status === 'success') {
      clearFields();

      timeoutId = setTimeout(() => {
        closeModal();
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [status]);

  const isValid = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

  return (
    <form onSubmit={onSubmit} autoComplete="on">
      <h2 className="font-display text-2xl mc__title">
        Get all the latest announcements directly to your inbox
      </h2>
      <div className="mt-6 grid gap-4 mc__field-container">
        <input
          type="text"
          name="given-name"
          placeholder="First name"
          className="input bg-neutral-800"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          autoComplete="on"
        />
        <input
          type="text"
          name="family-name"
          placeholder="Last name"
          className="input bg-neutral-800"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          autoComplete="on"
        />
        <input
          type="email"
          name="email"
          placeholder="Email*"
          className="input bg-neutral-800"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete="on"
        />
      </div>

      <div className="mt-6 flex items-center justify-between">
        <button
          className={`btn btn-primary ${isValid ? '' : 'btn-disabled'}`}
          onClick={onSubmit}
          disabled={!isValid}
        >
          Subscribe
        </button>

        <div className="ml-4">
          {status === 'sending' && <p className="text-info">sending...</p>}
          {status === 'error' && (
            <p
              className="text-error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            <p
              className="text-success"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </div>
    </form>
  );
};

import { HTMLAttributes } from 'react';
import { Stream } from '../types';

export const BadgeStyle: {
  [key in Stream]: HTMLAttributes<HTMLDivElement>['className'];
} = {
  [Stream.Android]: 'border-stream-android text-stream-android',
  [Stream.Web]: 'border-stream-web text-stream-web',
  [Stream.AI]: 'border-stream-ai text-stream-ai',
};

import { AnalyticsEvent, trackCustomEvent } from '../../utils/analytics';

export const Subscribe = () => {
  return (
    <div className="bg-neutral-800">
      <div
        id="subscribe"
        className="container mx-auto py-12 md:py-24 px-4 flex flex-col md:items-center reveal fade-bottom"
      >
        <h2 className="font-display text-xl md:text-2xl lg:text-4xl md:text-center">
          Get all the latest announcements directly to your inbox
        </h2>

        <label
          htmlFor="subscribe-modal"
          className="mt-4 md:mt-8 btn btn-primary modal-button px-10"
          onClick={() => {
            trackCustomEvent({
              category: 'Subscribe section',
              action: AnalyticsEvent.OpenModal,
              label: 'Subscribe',
            });
          }}
        >
          Subscribe
        </label>
      </div>
    </div>
  );
};
